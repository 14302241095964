<!--  -->
<template>
    <div class="row clearfix">
        <div>
            <div class="table-responsive" id="DomPdf">
                <h2 style="text-align: center "><b>用户个人信息基本表</b></h2>
                <table border="1" width="100%" cellpadding="6" cellspacing="0" class="tablecss">
                    <tbody>
                        <tr>
                            <td class="column1"><b>用户名</b></td>
                            <td colspan="2"></td>
                            <!-- <td><b>真实姓名</b></td>
                        <td><%=MyTools.removeNull(user.getRealname())%></td> -->
                            <td class="column1"><b>编号</b></td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td><b>性别</b></td>
                            <td colspan="2"></td>
                            <!-- <td><b>真实姓名</b></td>
                                                <td><%=MyTools.removeNull(user.getRealname())%></td> -->
                            <td><b>出生日期</b></td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td><b>身份证号</b></td>
                            <td colspan="2"></td>
                            <td><b>工作单位</b></td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td><b>身高</b></td>
                            <td colspan="1"></td>
                            <td><b>体重</b></td>
                            <td></td>
                            <td><b>邮箱</b></td>
                            <td colspan="1"></td>
                        </tr>
                        <tr>
                            <td><b>本人电话</b></td>
                            <td></td>
                            <td><b>联系人姓名</b></td>
                            <td></td>
                            <td><b>联系人电话</b></td>
                            <td colspan="1"></td>
                        </tr>
                        <tr>
                            <td><b>居住地</b></td>
                            <td colspan="2"></td>
                            <!-- <td colspan="2"><%=MyTools.removeNull(user.getProvince())+"  "+MyTools.removeNull(user.getCity())+
                                "  "+MyTools.removeNull(user.getCounty())+"  "+MyTools.removeNull(user.getTownship())+"  "+MyTools.removeNull(user.getVillage())+"  "+MyTools.removeNull(user.getAddr())%></td> -->

                            <td><b>户籍所在地</b></td>
                            <td colspan="2"></td>
                            <!-- <td colspan="2"><%=MyTools.removeNull(user.getProvince_p())+"  "+MyTools.removeNull(user.getCity_p())+
                                "  "+MyTools.removeNull(user.getCounty_p())+"  "+MyTools.removeNull(user.getTownship_p())+"  "+MyTools.removeNull(user.getVillage_p())+"  "+MyTools.removeNull(user.getAddr_p())%></td> -->
                        </tr>

                        <tr>
                            <td><b>常住类型</b></td>
                            <td></td>
                            <td><b>民族</b></td>
                            <td></td>
                            <td><b>婚姻状况</b></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><b>文化程度</b></td>
                            <td></td>
                            <td><b>职业</b></td>
                            <td></td>
                            <td><b>医疗费用支付方式</b></td>
                            <td colspan="1"></td>
                        </tr>

                        <tr>
                            <td><b>血型</b></td>
                            <td colspan="2"></td>
                            <!-- <td><b>RH</b></td>
                        <td><%=MyTools.removeNull(user.getBtRH())%></td> -->
                            <td><b>药物过敏史</b></td>
                            <td colspan="2"></td>

                        </tr>

                        <tr>
                            <td rowspan="18" style="vertical-align: middle;text-align: center;"><b>既往史</b></td>
                            <td rowspan="12" style="vertical-align: middle;text-align: center;"><b>疾病史</b></td>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>高血压</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>糖尿病</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>冠心病</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>慢性阻塞性肺疾病</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>恶性肿瘤</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>脑卒中</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>严重精神障碍</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>结核病</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>肝炎</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>其他法定传染病</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>职业病</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>
                        <tr>
                            <!-- <%if(disease_h.containsKey(diseaselist[0])){%> -->
                            <td><b><span>其他</span></b></td>
                            <td><b>确诊时间</b></td>
                            <td colspan="2"><b></b></td>
                            <!-- <%}else{%> -->
                        </tr>


                        <!-- <%
                        for(int i = 1; i<12;i++){
                    %>
                    <tr>
                        <%if(disease_h.containsKey(diseaselist[i])){%>
                        <td ><b><span style="color: red">√   <%=diseaselist[i]%></span></b></td>
                        <td><b>确诊时间</b></td>
                        <td colspan="2"><b><%=disease_h.get(diseaselist[i])%></b></td>
                        <%}else{%>
                        <td><b><span> <%=diseaselist[i]%></span></b></td>
                        <td><b>确诊时间</b></td>
                        <td colspan="2"><b>无</b></td>
                        <%}%>
                    </tr>
                    <%}%> -->

                        <tr>
                            <!-- <td rowspan="18" style="vertical-align: middle;text-align: center;"><b>既往史</b></td> -->
                            <td rowspan="2" style="vertical-align: middle;text-align: center;"><b>手术</b></td>
                            <td><b>名称1： </b></td>
                            <td><b></b></td>
                            <td><b>时间： </b></td>
                            <td><b></b></td>
                        </tr>
                        <tr>
                            <!-- <td rowspan="15" style="vertical-align: middle;text-align: center;"><b>既往史</b></td>
                                                <td rowspan="2"><b>手术</b></td> -->
                            <td><b>名称2： </b></td>
                            <td><b></b></td>
                            <td><b>时间： </b></td>
                            <td><b></b></td>
                        </tr>
                        <tr>
                            <!-- <td rowspan="18" style="vertical-align: middle;text-align: center;"><b>既往史</b></td> -->
                            <td rowspan="2" style="vertical-align: middle;text-align: center;"><b>外伤</b></td>
                            <td><b>名称1： </b></td>
                            <td><b></b></td>
                            <td><b>时间：</b></td>
                            <td><b></b></td>
                        </tr>
                        <tr>
                            <td><b>名称2： </b></td>
                            <td><b></b></td>
                            <td><b>时间：</b></td>
                            <td><b></b></td>
                        </tr>

                        <tr>
                            <!-- <td rowspan="18" style="vertical-align: middle;text-align: center;"><b>既往史</b></td> -->
                            <td rowspan="2" style="vertical-align: middle;text-align: center;"><b>输血</b></td>
                            <td><b>名称1： </b></td>
                            <td><b></b></td>
                            <td><b>时间： </b></td>
                            <td><b></b></td>
                        </tr>
                        <tr>
                            <td><b>名称2： </b></td>
                            <td><b></b></td>
                            <td><b>时间： </b></td>
                            <td><b></b></td>
                        </tr>


                        <tr>
                            <td rowspan="4" colspan="2" style="vertical-align: middle;text-align: center;"><b>家族史</b>
                            </td>
                            <td colspan="2" style="vertical-align: middle;text-align: center;"><b>父亲</b></td>
                            <td colspan="2"><b></b></td>
                        </tr>
                        <tr>
                            <td colspan="2" style="vertical-align: middle;text-align: center;"><b>母亲</b></td>
                            <td colspan="2"><b></b></td>
                        </tr>
                        <tr>
                            <td colspan="2" style="vertical-align: middle;text-align: center;"><b>兄弟姐妹</b></td>
                            <td colspan="2"><b></b></td>
                        </tr>
                        <tr>
                            <td colspan="2" style="vertical-align: middle;text-align: center;"><b>子女</b></td>
                            <td colspan="2"><b></b></td>
                        </tr>
                        <tr>
                            <td><b>暴露史</b></td>
                            <td colspan="1"></td>
                            <td style="vertical-align: middle;text-align: center;"><b>遗传病史</b></td>
                            <td colspan="1"><b></b></td>
                            <td style="vertical-align: middle;text-align: center;"><b>残疾状况</b></td>
                            <td colspan="1"><b></b></td>

                        </tr>

                        <tr>
                            <td rowspan="3" colspan="2" style="vertical-align: middle;text-align: center;"><b>生活环境</b>
                            </td>
                            <td colspan="2" style="vertical-align: middle;text-align: center;"><b>厨房排风设施</b></td>
                            <td colspan="2"><b></b></td>
                        </tr>
                        <tr>
                            <td style="vertical-align: middle;text-align: center;"><b>燃料类型</b></td>
                            <td><b></b></td>
                            <td style="vertical-align: middle;text-align: center;"><b>饮水</b></td>
                            <td><b></b></td>
                        </tr>

                        <tr>
                            <td style="vertical-align: middle;text-align: center;"><b>厕所</b></td>
                            <td><b></b></td>
                            <td style="vertical-align: middle;text-align: center;"><b>禽畜栏</b></td>
                            <td><b></b></td>
                        </tr>
                    </tbody>
                </table>
                <!-- </canvas><br> -->
            </div>
            <br>
            <el-button type="primary" plain href="perinfomodify_new.jsp" role="button">修改个人信息</el-button>
            <el-button type="primary" plain href="EnterprisesServlet?action=RelaList" role="button">加入小屋</el-button>
            <el-button type="primary" plain href="diseasehistoryentry.jsp" role="button">既往病史录入</el-button>
            <el-button type="primary" plain @click="getPdf('#DomPdf')">下载个人信息表</el-button>
            <!-- <el-button type="primary" plain onclick="download()">下载个人信息表</el-button> -->

        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';


export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

        };
    },
    //监听属性 类似于data概念
    computed: {
        htmlTitle: 'PsInfo',
        tableData1() {
            return [
                {
                    name1: '用户名', uid: '**', name2: '编号', iid: '**',
                    name3: '性别', name4: '出生日期',
                    name5: '身份证号', name6: '工作单位',
                    name7: '身高', name8: '体重', name9: '邮箱',
                    name10: '本人电话', name11: '联系人姓名', name12: '联系人电话',
                    name13: '居住地', name14: '户籍所在地',
                    name15: '常住类型', name16: '民族', name17: '婚姻状况',
                    name18: '文化程度', name19: '职业', name20: '医疗费用支付方式',
                    name21: '血型', name22: '药物过敏史',
                    name23: '既往史', name24: '疾病史',
                    name41: '家族史', name42: '父亲'
                },
                {
                    name24: '手术',

                }

            ]
        },
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        getInformation(){
            // console.log(this.uid)
            this.$http({
                url: this.$http.adornUrl('/personal/info/app/getPersonalInfo'),
                method: 'get',
                // data: this.$http.adornData({ uid: this.uid }),
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据")
                    console.log(data)
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.uid = this.$store.state.personal_module.uid
        
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        console.log(this.$route.query);
        if (this.$route.query.index) {
            console.log("看看是否传参：");
            console.log(this.$route.query.index);
        }
        this.getInformation();
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style >
.tablecss {
    table-layout: fixed;
    border: 1px solid lightgrey
}

.column1 {
    width: 15%
}

.column2 {
    width: 30%
}
</style>